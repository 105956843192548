<template>
  <div>
    <router-view @input="onInput"></router-view>
  </div>
</template>

<script>

export default {
  name: 'Event',
  data() {
    return {
      EventDetail: undefined,
    };
  },
  methods: {
    onInput(eventDetail) {
      this.EventDetail = eventDetail;
    },
  },
};
</script>
